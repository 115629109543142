<template>
  <v-touch
    v-on:swipeup="swipeup"
    v-on:swipedown="swipedown"
    :swipe-options="{ direction: 'vertical', threshold: 50 }"
    class="container birthday-border"
    id="container"
  >
    <img mode="widthFix" class="yezi" src="@/assets/images/birthday/yezi.png" />
    <img
      mode="widthFix"
      class="happy"
      src="@/assets/images/birthday/HAPPY BIRTHDAY TO YOU.png"
    />
    <img
      mode="widthFix"
      class="you"
      src="@/assets/images/birthday/You came into my life like an angel.png"
    />
    <div class="page1 open" v-show="pages[0]">
      <div class="img">
        <img
          mode="widthFix"
          class="img1"
          src="@/assets/images/birthday/sr1-1.png"
        />
        <van-image class="img2" :src="data[0].path">
          <img
            mode="widthFix"
            class="best"
            src="@/assets/images/birthday/best.png"
          />
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image>
      </div>
      <div class="text">
        <div class="title">
          <div class="line pos1"></div>
          <span class="text1" :style="getStyle(data[1])">{{
            data[1].content
          }}</span>
          <div class="line pos2"></div>
        </div>
        <span class="text2" :style="getStyle(data[2])">{{
          data[2].content
        }}</span>
      </div>
    </div>
    <div class="page2 open" v-show="pages[1]">
      <div class="img">
        <img mode="widthFix" class="img1" :src="data[3].path" />
        <div class="img2And3">
          <img mode="widthFix" class="img2" :src="data[4].path" />
          <img mode="widthFix" class="img3" :src="data[5].path" />
        </div>
      </div>

      <div class="text">
        <span :style="getStyle(data[6])">{{ data[6].content }}</span>
        <div class="line1"></div>
        <div class="line2"></div>
      </div>
    </div>
    <div class="page3 open" v-show="pages[2]">
      <img mode="widthFix" :src="data[7].path" />
      <div class="text">
        <span :style="getStyle(data[8])">{{ data[8].content }}</span>
        <div class="line1"></div>
        <div class="line2"></div>
      </div>
    </div>
    <div class="page4 open" v-show="pages[3]">
      <div class="img1And2">
        <img mode="widthFix" :src="data[9].path" class="img1" />
        <img mode="widthFix" :src="data[10].path" class="img2" />
      </div>
      <div class="text">
        <span :style="getStyle(data[11])">{{ data[11].content }}</span>
        <img mode="widthFix" src="@/assets/images/birthday/sr4-3.png" />
      </div>
    </div>
    <div class="page5 open" v-show="pages[4]">
      <img class="box1" mode="widthFix" :src="data[12].path" />
      <div class="textAndImg">
        <div class="text">
          <span :style="getStyle(data[13])">{{ data[13].content }}</span>
        </div>
        <img class="box2" mode="widthFix" :src="data[14].path" />
      </div>
      <img
        mode="widthFix"
        src="@/assets/images/birthday/sr5-3.png"
        class="img3"
      />
    </div>
    <div class="page6 open" v-show="pages[5]">
      <img class="box" mode="widthFix" :src="data[15].path" />
      <div class="text">
        <span :style="getStyle(data[16])">{{ data[16].content }}</span>
        <div class="line1"></div>
        <div class="line2"></div>
      </div>
    </div>
    <div class="page7 open" v-show="pages[6]">
      <div class="img1And2">
        <img mode="widthFix" :src="data[17].path" class="img1" />
        <img
          mode="widthFix"
          src="@/assets/images/birthday/sr7-2.png"
          class="img2"
        />
      </div>
      <div class="img3And4">
        <img
          mode="widthFix"
          src="@/assets/images/birthday/sr7-3.png"
          class="img3"
        />
        <img mode="widthFix" :src="data[18].path" class="img4" />
      </div>
      <div class="text-wrap">
        <div class="text">
          <span :style="getStyle(data[19])">{{ data[19].content }}</span>
        </div>
        <img mode="widthFix" src="@/assets/images/birthday/sr7-5.png" />
      </div>
    </div>
    <div class="page8 open" v-show="pages[7]">
      <div class="box">
        <img
          mode="widthFix"
          src="@/assets/images/birthday/sr8-1.png"
          class="img1"
        />
        <img mode="widthFix" :src="data[20].path" class="img2" />
      </div>
      <div class="text">
        <span :style="getStyle(data[21])">{{ data[21].content }}</span>
      </div>
      <img
        mode="widthFix"
        src="@/assets/images/birthday/sr8-3.png"
        class="img3"
      />
    </div>
  </v-touch>
</template>

<script>
import turnPage from "@/mixins/turnPage";
import birthdayData from "@/assets/data/birthday";
export default {
  mixins: [turnPage],
  name: "Birthday",
  props: {
    updCurr: {
      type: Function,
    },
    list: {
      type: Array,
    },
    preview: {
      type: Boolean,
    },
  },
  data() {
    return {
      pages: [1, 0, 0, 0, 0, 0, 0, 0],
      index: 0,
      slideAnimation: "openUp",
      data: JSON.parse(JSON.stringify(birthdayData)),
    };
  },
  methods: {
    swipeup() {
      if (this.index == this.pages.length - 1) return;
      this.$set(this.pages, this.index, 0);
      this.index++;
      this.$set(this.pages, this.index, 1);
      this.updCurr && this.updCurr(this.index + 1);
    },
    swipedown() {
      if (this.index == 0) return;
      this.$set(this.pages, this.index, 0);
      this.index--;
      this.$set(this.pages, this.index, 1);
      this.updCurr && this.updCurr(this.index + 1);
    },
    getStyle(data) {
      let percent =
        this.$route.name == "display" ||
        this.preview ||
        this.$route.name == "finished"
          ? 1
          : 0.6;
      return {
        fontFamily: data.fontFamily,
        fontSize: data.fontSize * percent + "px",
        color: data.fontColor,
        lineHeight: data.fontLineHeight * percent + "px",
        letterSpacing: data.fontSpacing * percent + "px",
        whiteSpace: "pre-wrap",
      };
    },
  },
  watch: {
    list: {
      immediate: true,
      handler() {
        if (this.list) {
          this.data = this.list;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(105, 52, 0, 1);
}
span {
  display: block;
  width: 100%;
  text-align: center;
  line-height: 32px;
  opacity: .9;
}
img {
  display: block;
}
.text {
  animation: fale 1s ease-in-out;
}
.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/birthday/background.png");
  background-size: contain;
  background-repeat: repeat;
  overflow: hidden;
  .yezi {
    position: absolute;
    width: 44%;
    left: 0;
    bottom: 12%;
  }
  .happy {
    position: absolute;
    width: 33.8%;
    color: rgba(135, 135, 135, 1);
    left: 2.4%;
    top: 4%;
  }
  .you {
    position: absolute;
    width: 29.2%;
    color: rgba(135, 135, 135, 1);
    right: 1%;
    top: 4%;
  }

  & > div {
    position: relative;
    height: 100%;
  }
}
.open {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.page1 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .img {
    display: flex;
    flex-direction: column;
    align-items: center;
    .img1 {
      width: 65%;
      margin: 15% auto 0;
      animation: scale 1s ease-in-out;
    }
    .img2 {
      position: relative;
      width: 65%;
      margin: 0 auto;
      border-radius: 500px 500px 0 0;
      overflow: hidden;
      animation: bottom 1s ease-in-out;
      .best {
        position: absolute;
        width: 75.5%;
        bottom: 5%;
        left: 12.25%;
      }
    }
  }

  .text {
    position: relative;
    width: 85.75%;
    margin: 5% auto 10%;
    border: 1px solid rgba(105, 52, 0, 1);
    color: rgba(105, 52, 0, 1);
    padding: 4%;

    .title {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .text1 {
        width: 50%;
        font-size: 4.83vw;
        text-align: center;
        color: rgba(105, 52, 0, 1);
      }

      .line {
        // position: absolute;
        width: 8.2vw;
        height: 3px;
        background-color: rgba(105, 52, 0, 1);
      }
      // .pos1 {
      //   top: 8vw;
      //   left: 16vw;
      // }
      // .pos2 {
      //   top: 8vw;
      //   right: 16vw;
      // }
    }

    .text2 {
      width: 100%;
      margin: 1.7% auto 0;
      text-align: center;
      line-height: 50px;
      font-size: 3.86vw;
      color: rgba(105, 52, 0, 1);
    }
  }
}
.page2 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .img1 {
    width: 84.3%;
    margin: 19% auto 0;
    border: 10px solid #fff;
    box-shadow: 1px 5px 13px 0px rgba(0, 0, 0, 0.25);
    animation: right 1s ease-in-out;
  }
  .img2And3 {
    display: flex;
    width: 84.3%;
    justify-content: space-between;
    margin: 3.2% auto 0;
    overflow: hidden;
    .img2 {
      width: 48%;
      border: 10px solid #fff;
      box-shadow: 1px 5px 13px 0px rgba(0, 0, 0, 0.25);
      animation: left 1s ease-in-out;
    }
    .img3 {
      width: 39.5%;
      border: 10px solid #fff;
      box-shadow: 1px 5px 13px 0px rgba(0, 0, 0, 0.25);
      margin: auto;
      animation: fale 1s ease-in-out;
    }
  }
  .text {
    position: relative;
    margin: 10px auto 15%;
    width: 75%;

    .line1 {
      position: absolute;
      width: 2%;
      height: 70%;
      background: rgba(105, 52, 0, 1);
      right: -4%;
      bottom: -16%;
    }
    .line2 {
      position: absolute;
      width: 40%;
      height: 1%;
      background: rgba(105, 52, 0, 1);
      bottom: -8%;
      right: -12%;
    }
  }
}
.page3 {
  img {
    width: 70%;
    border: 10px solid #fff;
    box-shadow: 1px 5px 13px 0px rgba(0, 0, 0, 0.25);
    margin: 20.3% auto 0;
    animation: fale 1s ease-in-out;
  }
  .text {
    width: 75%;
    position: relative;
    margin-bottom: 15%;

    .line1 {
      position: absolute;
      width: 2.5%;
      height: 80%;
      background: rgba(105, 52, 0, 1);
      left: -6%;
      bottom: -25%;
    }
    .line2 {
      position: absolute;
      width: 35%;
      height: 2.5%;
      background: rgba(105, 52, 0, 1);
      bottom: -18%;
      left: -10%;
    }
  }
}
.page4 {
  .img1And2 {
    position: relative;
    width: 80%;
    height: 50%;
    margin: 15.7vw auto 0;
    img {
      position: absolute;
      width: 70%;
      border: 10px solid #fff;
      box-shadow: 1px 5px 13px 0px rgba(0, 0, 0, 0.25);
    }
    .img1 {
      left: 0;
      top: 0;
      animation: left 1s ease-in-out;
    }
    .img2 {
      right: 0;
      bottom: 0;
      animation: right 1s ease-in-out;
    }
  }
  .text {
    position: relative;
    width: 75%;
    margin: 10% 0 20% 10%;
    span {
      text-align: left;
    }
    img {
      position: absolute;
      width: 80%;
      right: -15%;
      bottom: -25%;
    }
  }
}
.page5 {
  .box1 {
    position: relative;
    display: block;
    width: 75%;
    transform: translateX(-10px);
    background-color: #fff;
    box-shadow: 10px 10px #fff, 10px 10px 13px 0px rgba(0, 0, 0, 0.25);
    margin: 25% auto 12.5%;
    animation: fale 1s ease-in-out;
  }
  .textAndImg {
    display: flex;
    width: 90%;
    justify-content: space-evenly;
    align-items: center;
    margin: 4% auto;
    .text {
      span {
        text-align: left;
      }
    }
    .box2 {
      position: relative;
      display: block;
      width: 40%;
      background-color: #fff;
      box-shadow: 1px 5px 13px 0px rgba(0, 0, 0, 0.25);
      box-shadow: 10px 10px #fff, 10px 10px 13px 0px rgba(0, 0, 0, 0.25);
      animation: right 1s ease-in-out;
    }
  }
  .img3 {
    width: 65%;
    transform: translateX(20%);
    margin-bottom: 10%;
  }
}
.page6 {
  .box {
    position: relative;
    display: block;
    width: 70%;
    box-shadow: -10px -10px rgba(176, 160, 144, 1), 10px 10px #fff,
      10px 10px 13px 0px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    margin: 26% auto 0;
    animation: fale 1s ease-in-out;
  }
  .text {
    position: relative;
    width: 80%;
    margin-bottom: 20%;
    span {
      text-align: left;
    }
    .line1 {
      position: absolute;
      width: 2%;
      height: 80%;
      background: rgba(105, 52, 0, 1);
      right: 0%;
      bottom: -20%;
    }
    .line2 {
      position: absolute;
      width: 40%;
      height: 1%;
      background: rgba(105, 52, 0, 1);
      bottom: -10%;
      right: -5%;
    }
  }
}
.page7 {
  .img1And2 {
    display: flex;
    width: 80%;
    background-color: #fff;
    margin-top: 15%;
    box-shadow: 1px 5px 13px 0px rgba(0, 0, 0, 0.25);
    align-items: center;
    justify-content: space-evenly;
    animation: left 1s ease-in-out;
    transform: translateX(-4%);

    .img1 {
      width: 75%;
      margin: 3.6%;
      margin-right: 0;
    }
    .img2 {
      width: 8%;
      margin: auto 5%;
    }
  }
  .img3And4 {
    display: flex;
    width: 80%;
    background-color: #fff;
    box-shadow: 1px 5px 13px 0px rgba(0, 0, 0, 0.25);
    align-items: center;
    justify-content: space-evenly;
    animation: right 1s ease-in-out;
    transform: translateX(4%);
    margin-bottom: 5%;

    .img3 {
      width: 8%;
      margin: auto 5%;
    }
    .img4 {
      width: 75%;
      margin: 3.6%;
      margin-left: 0;
    }
  }
  .text-wrap {
    display: flex;
    width: 90%;
    justify-content: space-evenly;
    margin-bottom: 15%;
    .text {
      width: 60%;
      span {
        text-align: left;
      }
    }
    img {
      width: 38%;
    }
  }
}
.page8 {
  .box {
    position: relative;
    width: 70%;
    margin: 20% auto 0;
    .img1 {
      position: absolute;
      width: 66%;
      left: -6%;
      top: -25%;
      animation: scale 1s ease-in-out;
      z-index: 0;
    }
    .img2 {
      position: relative;
      z-index: 1;
      width: 100%;
      border-radius: 50%;
      overflow: hidden;
      animation: fale 1s ease-in-out;
    }
  }
  .text {
    width: 60%;
  }
  .img3 {
    width: 65%;
    margin: 0 auto 10%;
    animation: right 1s ease-in-out;
  }
}

@keyframes fale {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes left {
  0% {
    opacity: 0.2;
    transform: translateX(-100vw);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes right {
  0% {
    opacity: 0.2;
    transform: translateX(100vw);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scale {
  0% {
    transform: scale(0.3);
  }
  66% {
    transform: scale(1.2);
  }
  33%,
  100% {
    transform: scale(1);
  }
}

@keyframes bottom {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>