export default {
    data() {
        return {
            count: 0,
            scrollTimer: null
        }
    },
    methods: {
        autoScroll() {
            if (this.index == this.pages.length - 1) {
                clearInterval(this.timer)
                this.timer = null
                return;
            }
            // 触发滚动方法
            this.scrollFun();
            // 去除点击监听
            window.document.removeEventListener("touchstart", this.pauseScroll);
            // 重设点击监听
            window.document.addEventListener("touchstart", this.pauseScroll, false);
        },
        pauseScroll() {
            // 定时器不为空
            if (this.scrollTimer) {
                // 清除定时器
                clearInterval(this.scrollTimer);
                this.scrollTimer = null;
                // 一秒钟后重新开始定时器
                this.pauseTimer = setTimeout(() => {
                    this.scrollFun();
                }, 2000);
            }
        },
        scrollFun() {
            // 如果定时器存在
            if (this.scrollTimer) {
                // 则先清除
                clearInterval(this.scrollTimer);
                this.scrollTimer = null;
            }
            this.scrollTimer = setInterval(() => {
                this.$set(this.pages, this.index, 0);
                this.index++;
                this.$set(this.pages, this.index, 1);
                if (this.index == this.pages.length - 1) {
                    // 滚动到底部 停止定时器
                    clearInterval(this.scrollTimer);
                    this.scrollTimer = null;
                }
            }, 5000);
        },
        closeAutoScroll() {
            clearTimeout(this.pauseTimer)
            this.pauseTimer = null
            clearInterval(this.scrollTimer)
            this.scrollTimer = null
            // 清理点击监听
            window.document.removeEventListener('touchstart', this.pauseScroll)
        }
    },
    mounted() {
        // let imgList = document.querySelectorAll("img");
        // imgList.forEach((item) => {
        //   this.count++;
        //   item.onload = () => {
        //     this.count--;
        //     if (this.count === 0) {
        //       this.autoScroll();
        //     }
        //   };
        // });
    },
    destroyed() {
        this.closeAutoScroll()
    },
}